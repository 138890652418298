<template>
  <div id="chgcode">
    <!-- 未登录 忘记密码 -->
    <div class="container">
      <!-- 重置密码 -->
      <div v-show="toggle" class="resetCode">
        <div class="vertical_center">
          <img src="~@/assets/icon/icon_lock.png" class="icon-lock" /><span
            class="title"
          >
            重置密码</span
          >
        </div>
        <el-input v-model="account" placeholder="邮箱/手机号"></el-input>
        <el-input
          v-model="message_code"
          placeholder="邮件/短信验证码"
          class="code"
        ></el-input>
        <el-button
          type="info"
          v-show="messageCode"
          style="background:#a6a9ad;width:114px;"
          @click.native="getRebackMessageCode()"
          round
          ><span></span>获取验证码</el-button
        >
        <el-button
          type="info"
          v-show="!messageCode"
          style="background:#a6a9ad;width:140px;"
          round
          >(<span class="countDown">{{ count_down }}</span
          >s)后重新获取</el-button
        >
        <el-button class="next" autofocus round @click="goNext"
          >下一步</el-button
        >
      </div>
      <!-- 设置密码 -->
      <div v-show="!toggle" class="setCode">
        <div class="vertical_center">
          <img src="~@/assets/icon/icon_lock.png" class="icon-lock" /><span
            class="title"
          >
            设置密码</span
          >
        </div>
        <el-input
          v-model="fresh_code"
          show-password
          placeholder="新密码"
        ></el-input>
        <el-input
          v-model="fresh_code_verify"
          placeholder="确认新密码"
          show-password
        ></el-input>
        <el-button class="next" autofocus round @click="goSave">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { INSERT, UPDATE, QUERYED, UPDATES } from "@/services/dao";
import axios from "axios";
export default {
  data() {
    return {
      account: "", //手机或邮箱号
      messageCode: true, // 短信验证码倒计时切换
      message_code: "",
      back_message_code: "123456", //后台返回的验证码
      count_down: 60, // 倒计时
      toggle: true, //切换
      fresh_code: "", //新密码
      fresh_code_verify: "", // 确认新密码
      case_flag: 0, // 1--手机 2--邮箱
      first_phone: "", // 接收短信验证码的手机号
      first_email: "", // 接收邮箱验证码的邮箱地址
      userID: "",
    };
  },
  methods: {
    // 获取短信验证码倒计时动画
    getMessageCode() {
      console.log("点击");
      this.messageCode = false;
      let time = 59;
      let t = setInterval(() => {
        this.count_down = time;
        time--;
        if (time < 0) {
          this.count_down = 60;
          clearInterval(t);
          this.messageCode = true;
        }
      }, 1000);
    },
    // 请求后台 发送验证码
    async getRebackMessageCode() {
      // console.log("点击拿验证码")
      // 要获取验证码的时候就要进行邮箱或者手机号验证
      let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      let reg_email = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.]){1,2}[A-Za-z\d]{2,5}$/g;
      // 是否乱填
      if (!myreg.test(this.account) && !reg_email.test(this.account)) {
        this.$message.warning("请填写正确的信息!");
        return;
      }

      if (myreg.test(this.account)) {
        // 判断为手机
        // console.log(' 判断为手机号');

        // 查询数据库中是否有该手机号
        let search = await QUERYED(
          "post",
          "",
          'PCUsers(where:{phone:{_eq:"' + this.account + '"}}) {id}'
        );

        // console.log("查找数据库 该手机号是否存在: ",search.data.PCUsers.length>0)

        if (!search.data.PCUsers.length > 0) {
          this.$message.warning("该手机号未被注册!");
          return;
        }
        this.userID = search.data.PCUsers[0].id;
        this.case_flag = 1;
        this.getMessageCode(); // 只要 邮箱 手机号填对一个 并是已注册 就可以进行倒计时
        await axios
          .post(this.api.LoginURL.concat("/user/sendVCode"), {
            phone: this.account,
          })
          .then((res) => {
            // console.log("拿到后台短信验证码cb: ",res);
            // 给 back_message_code赋值
            if (!res.data.success) {
              this.$message.warning(res.data.respMsg);
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log(" 判断为邮箱");
        this.case_flag = 2;
        // 判断为邮箱

        // 查询数据库中是否有该邮箱
        let search = await QUERYED(
          "post",
          "",
          'PCUsers(where:{email:{_eq:"' + this.account + '"}}) {id loginName}'
        );

        // console.log("查找数据库 该邮箱是否存在: ",search)

        if (!search.data.PCUsers.length > 0) {
          this.$message.warning("该邮箱账号未被注册!");
          return;
        }
        let loginName = search.data.PCUsers[0].loginName;
        this.userID = search.data.PCUsers[0].id;
        this.getMessageCode(); // 只要 邮箱 手机号填对一个 并是已注册 就可以进行倒计时

        await axios
          .post(this.api.LoginURL.concat("/user/registerByEmail"), {
            loginName: loginName,
            email: this.account,
          })
          .then((res) => {
            // console.log("请求邮箱地址cb: ",res);
            if (!res.data.success) {
              this.$message.warning(res.data.respMsg);
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // 下一步
    async goNext() {
      // 判断是否填写手机号或者邮箱地址
      let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      let reg_email = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.]){1,2}[A-Za-z\d]{2,5}$/g;
      // 是否乱填
      if (!myreg.test(this.account) && !reg_email.test(this.account)) {
        this.$message.warning("请填写正确的信息!");
        return;
      }
      // 判断验证码是否正确填写
      if (this.message_code != "") {
        // 将用户填写的验证码 传入后端验证
        if (this.case_flag == 1) {
          // 传入短信验证码
          // console.log("register->phone");
          await axios
            .post(this.api.LoginURL.concat("/user/register"), {
              phone: this.account,
              vcode: this.message_code,
            })
            .then((res) => {
              // console.log("用户填的短信验证码发到后台cb: ",res);
              if (!res.data.success) {
                this.$message.warning(res.data.respMsg);
                return;
              }
              // 验证码与所填信息存在则进入下一步
              this.toggle = !this.toggle;
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          // 传入邮箱验证码
          // console.log("register->email");
          await axios
            .post(this.api.LoginURL.concat("/user/register"), {
              email: this.account,
              vcode: this.message_code,
            })
            .then((res) => {
              // console.log("用户填的邮箱验证码发到后台cb: ",res);
              if (!res.data.success) {
                this.$message.warning(res.data.respMsg);
                return;
              }
              // 验证码与所填信息存在则进入下一步
              this.toggle = !this.toggle;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        this.$message.warning("请填写验证码!");
        return;
      }
      // 验证码与所填信息存在则进入下一步
      // this.toggle = !this.toggle;
    },
    // 保存
    async goSave() {
      // 验证填写密码
      if (this.fresh_code != "" && this.fresh_code_verify != "") {
        let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,30}$/;
        if (!reg.test(this.fresh_code)) {
          this.$message.warning(
            "8位及以上密码且必须同时包含数字、字母,且不能用特殊字符'"
          );
          return;
        }
        if (this.fresh_code_verify != this.fresh_code) {
          this.$message.warning("两次密码填写不一致!");
          return;
        }
      } else {
        this.$message.warning("请完善密码信息!");
        return;
      }

      // 发送请求
      if (this.case_flag == 1) {
        // 通过手机号更改的密码
        let update = await UPDATES(
          "POST",
          "",
          'update_User(where:{phone:{_eq:"' +
            this.account +
            '"}},_set:{loginPassword:"' +
            this.$md5(this.fresh_code) +
            '"}) {affected_rows}'
        );
        // console.log("199")
        if (update.data.update_User.affected_rows == 1) {
          // console.log("201")
          this.$message.success("更改成功!");
          this.$router.push("login");
        } else {
          this.$message.warning("更改失败!");
        }
      } else {
        // 通过邮箱更改的密码
        // console.log("this.account: ",this.account);
        let cb = await UPDATES(
          "POST",
          "",
          'update_User(where:{email:{_eq:"' +
            this.account +
            '"}},_set:{loginPassword:"' +
            this.$md5(this.fresh_code) +
            '"}) {affected_rows}'
        );
        // console.log("211")
        let flag = cb.data.update_User.affected_rows;
        // console.log("cb: ",cb);
        // console.log("flag: ",flag);
        if (flag == 1) {
          // console.log("213")
          this.$message.success("更改成功!");
          this.$router.push("login");
        } else {
          this.$message.warning("更改失败!");
        }
      }
    },
  },
};
</script>

<style scoped>
#chgcode {
  width: 100%;
  height: 100%;
  background: url(~@/assets/img/Vol_006_SE182.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
}
.container {
  position: absolute;
  width: 25%;
  height: 40%;
  margin: 0 auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  padding: 20px;
}
.vertical_center {
  text-align: center;
}
.title {
  font-size: 14px;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
  vertical-align: 21px;
}
.icon-lock {
  vertical-align: 0px;
}
.code {
  width: 55%;
}
.container >>> .el-input__inner {
  border-radius: 20px;
}
.container .el-button + .el-button {
  margin-left: 0px;
}
.next {
  background: #1b2343;
  cursor: pointer;
  color: #ffffff;
  display: block;
  width: 100%;
  border: 0;
  margin-top: 10px;
}
.container >>> .el-input {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
